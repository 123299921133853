import React from 'react'
import Layout from '../components/Layout'

const Photos = () => {
  return (
    <Layout>
      <div>Sorry, the page you're looking for is not here..</div>
    </Layout>
  )
}

export default Photos
