import React, { ReactChild } from "react"
import { Link } from "gatsby"
import { createUseStyles } from "react-jss"
import home from "../images/home.svg"

const useStyles = createUseStyles(theme => ({
  root: {
    display: "flex",
    flexDirection: "column",
    height: "100vh",
  },
  appBar: {
    display: "flex",
    height: 60,
    background: "#000",
    boxShadow: "0px 0px 2px 3px rgba(0, 0, 0, 0.86)",
    color: "#fff",
    position: "relative",
    zIndex: 1,
  },
  image: {
    height: "100%",
  },
  imageWrap: {
    padding: ".5rem",
  },
  actions: {
    display: "flex",
    marginLeft: "auto",
    marginRight: "1rem",
  },
  main: {
    paddingTop: 10,
    display: "flex",
    overflowY: "auto",
    width: "100vw",
  },

  linkWrap: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    height: "100%",
    paddingRight: "1rem",
    paddingLeft: "1rem",
  },
  link: {
    textDecoration: "none",
    border: "2px solid #fff",
    padding: ".3rem .6rem",
    transition: "all .3s",
    "&:hover": {
      background: "#3d3d3d",
      borderColor: "#ddd",
    },
  },
  linkTxt: {
    color: "#fff",
  },
}))

export type LinkAction = {
  txt: string
  to: string
}

const AppBar = (props: { links?: Array<LinkAction> }) => {
  const c = useStyles()
  const { links = [] } = props

  return (
    <div className={c.appBar}>
      <div className={c.imageWrap}>
        <Link to={"/"}>
          <img className={c.image} src={home} />
        </Link>
      </div>
      <div className={c.actions}>
        {links.map(x => (
          <div className={c.linkWrap} key={x.txt}>
            <Link to={x.to} className={c.link}>
              <p className={c.linkTxt}>{x.txt}</p>
            </Link>
          </div>
        ))}
      </div>
    </div>
  )
}

const Layout = (props: { children: ReactChild; links?: Array<LinkAction> }) => {
  const c = useStyles()
  const { children, links } = props
  return (
    <div className={c.root}>
      <AppBar links={links} />
      <main className={c.main}>{children}</main>
    </div>
  )
}

export default Layout
